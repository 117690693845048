<template>
  <v-alert dense outlined type="error">
    {{ message }}
  </v-alert>
</template>
<script>
export default {
  name: 'Alert',
  props: ['message'],
};
</script>
